<template>
  <v-form ref="createCampaignForm" v-model="validCampaign">
    <v-card>
      <v-card-title class="white--text primary">Create Campaign</v-card-title>
      <v-row class="mx-4 py-4">
        <v-col cols="12" class="pa-4 text-center">
          <v-avatar size="200" tile>
            <img
              style="cursor:pointer;"
              @click="$refs.campaignLogo.click()"            
              :src="croppedImgB64 === null ? 'd-clamp-holder.jpg' : croppedImgB64"
            />
          </v-avatar>
          <input @change='addNewImgToCropper' ref='campaignLogo' style="display:none;" type="file" accept="image/*">
        </v-col>
        <v-col cols="12">
          <v-dialog v-model="cropperDialog" width="600">
            <v-card class="pa-4">
              <v-col cols="12">
                <cropper ref="cropper" class="cropper" :src="imgUrl" />
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="cropImage">crop</v-btn>
              </v-col>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12"
          ><v-text-field
            outlined
            v-model="campaign.name"
            label="Name*"
            maxlength="50"
            :rules="requiredRule"
          ></v-text-field
        ></v-col>
        <v-col cols="6"
          ><v-text-field
            outlined
            v-model="campaign.folderName"
            maxlength="50"
            label="Folder Name*"
            :rules="requiredRule"
            class="mt-n7"
          ></v-text-field
        ></v-col>
        <v-col cols="6"
          ><v-text-field
            outlined
            v-model="campaign.kNumber"
            label="K Number"
            maxlength="50"
            class="mt-n7"
          ></v-text-field
        ></v-col>
        <v-col cols="12">
          <v-textarea
            v-model="campaign.description"
            maxlength="1000"
            label="Description (Include search tags)"
            rows="1"
            auto-grow
            outlined
            hide-details
            class="mt-n7 textarea-padded"
          />
        </v-col>
        <v-col cols="12">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                :value="sanitizedDate(campaign)"
                outlined
                range
                label="Start/End Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-combobox>
            </template>
            <v-date-picker 
              v-model="campaign.date"
              @change="updateCampaignDate()"
              range
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Track Campaign 
        <v-col cols="12" class="d-flex align-center my-n6 mt-n12 mx-1" style="gap: 1em">
          <h3>Track campaign</h3>
          <v-switch v-model="trackSwitch" dense />
        </v-col>
        <v-row v-if="trackSwitch" class="mt-2" style="margin: 0 0.149em">
          <v-col cols="6">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  :value="sanitizedLiveDate(campaign)"
                  outlined
                  range
                  label="Start/End Live Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker 
                v-model="campaign.liveDate"
                @change="updateLiveCampaignDate()"
                range
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  :value="sanitizedDeliveryDate(campaign)"
                  outlined
                  range
                  label="Start/End Delivery Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker 
                v-model="campaign.deliveryDate"
                @change="updateDeliveryCampaignDate()"
                range
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  :value="sanitizedProductionDate(campaign)"
                  outlined
                  range
                  label="Start/End Production Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-n7"
                ></v-combobox>
              </template>
              <v-date-picker 
                v-model="campaign.productionDate"
                @change="updateProductionCampaignDate()"
                range
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          
          <v-col cols="12">
            <h3></h3>
          </v-col>
        </v-row>
        -->
        <v-col cols="12" class="text-right">
          <v-btn @click="createCampaign()" color="primary" class="mr-2" >Save</v-btn>
          <v-btn @click="$emit('closeEditDialog')" color="red">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
// Components
import CampaignController from "@/services/controllers/Campaign"

// External libraries
import moment from 'moment'
import { Cropper, CircleStencil } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

export default {
  components: {
    Cropper,
    CircleStencil,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "Field is required"],
      campaign: {},
      validCampaign: false,
      uploadedCampaignLogo: null,
      croppedCampaignLogoUrl: null,
      campaignDateRangePicker: false,
      cropperDialog: false,
      imgUrl: null,
      logoMediaId: null,
      croppedImgB64: null,
      croppedImg: null,
      trackSwitch: false,
      // Duration of sprint between each phase
      sprintTime: 10,
    };
  },
  watch: {
    campaign: {
      handler: function (val) {
        // if campaign.date is not empty then assign campaign.startDate and campaign.endDate to campaign liveStartDate and liveEndDate respectively, but subtract 10 day from each
        if (val.date) {
          this.campaign.liveStartDate = moment(val.date[1]).subtract(this.sprintTime, 'days').format('YYYY-MM-DD')
          this.campaign.liveEndDate = val.date[1]
          // Delivery date
          this.campaign.deliveryStartDate = moment(val.date[1]).subtract(this.sprintTime*2, 'days').format('YYYY-MM-DD')
          this.campaign.deliveryEndDate = this.campaign.liveStartDate
          // Production date
          this.campaign.productionStartDate = moment(val.date[1]).subtract(this.sprintTime*3, 'days').format('YYYY-MM-DD')
          this.campaign.productionEndDate = this.campaign.deliveryEndDate
        }
        
      },
      deep: true,
    },
  },
  methods: {
    // Purpose: Handler function for campaign end points
    async createCampaign() {
      let newCampaign, resLogo, b64res

      // Create campaign details
      this.$refs.createCampaignForm.validate()

      if(this.validCampaign) {
        newCampaign = await this.createCampaignDetails()
      }

      // Create campaign logo
      if (this.croppedImgB64 !== null) {
        const form = new FormData();

        form.append("fileBase64", this.croppedImgB64)
        form.append("campaignId", newCampaign.data.id)

        b64res = await CampaignController.uploadB64(form).catch(err => {
          this.$root.$emit("snackbarError", ''+ err.response.data.message)
        })

        if(b64res) {
          resLogo = await this.createCampaignLogo(b64res.data, newCampaign.data)
        }
      }

      // If either end point is called and successful then show snackbar
      if(newCampaign || resLogo) {
        this.$emit("getCampaignByIdFromChild");
        this.$emit("closeEditDialog");
        this.$root.$emit( "snackbarSuccess", "Campaign Set up Successfully" );
        this.campaign = {};
      }
    },
    //
    // Purpose: Function to edit campaign details
    async createCampaignDetails() {
      const res = await CampaignController.createCampaign({
        name: this.campaign.name,
        campaignFolderName: this.campaign.folderName,
        kNumber: this.campaign.kNumber,
        description: this.campaign.description,
        startDate: this.campaign.startDate,
        endDate: this.campaign.endDate,
      }).catch(err => {
        this.$root.$emit('snackbarError', ''+ err.response.data.message)
      })
      
      return res
    },

    // Purpose: Function to upload campaign logo
    async createCampaignLogo(b64res, campaign) {
      const res = await CampaignController.setLogo({
        mediaId: b64res.id,
        campaignId: campaign.id,
      }).catch(err => {
        this.$root.$emit("snackbarError", ''+ err.response.data.message);
      })

      this.croppedImgB64 = null;
      this.uploadedCampaignLogo = null;

      return res
    },
    //update Camapign Live date
    updateLiveCampaignDate() {
      if(this.campaign.liveDate) {
        this.campaign.liveStartDate = this.campaign.liveDate[0]
        this.campaign.liveEndDate = this.campaign.liveDate[1]
      }
    },
    // Purpose: Update start/end date of the local campaign variable
    updateCampaignDate() {
      if(this.campaign.date) {
        this.campaign.startDate = this.campaign.date[0]
        this.campaign.endDate = this.campaign.date[1]
      }
    },
    //update Camapign delivery date
    updateProductionCampaignDate() {
      if(this.campaign.productionDate) {
        this.campaign.productionStartDate = this.campaign.productionDate[0]
        this.campaign.productionEndDate = this.campaign.productionDate[1]
      }
    },
    ///update Camapign delivery date
    updateProductionCampaignDate() {
      if(this.campaign.deliveryDate) {
        this.campaign.deliveryStartDate = this.campaign.deliveryDate[0]
        this.campaign.deliveryEndDate = this.campaign.deliveryDate[1]
      }
    },
    // Purpose: Sanitize start/end campaign.DeliverystartDate
    sanitizedDeliveryDate(campaign) {
      if(campaign.deliveryStartDate && campaign.deliveryEndDate) {
        return moment(campaign.deliveryStartDate).format('DD MMM YYYY')  + ' - ' + moment(campaign.deliveryEndDate).format('DD MMM YYYY') 
      }
      return 'Not Set'
    },
    // Purpose: Sanitize start/end campaign.productiontartDate
    sanitizedProductionDate(campaign) {
      if(campaign.productionStartDate && campaign.productionEndDate) {
        return moment(campaign.productionStartDate).format('DD MMM YYYY')  + ' - ' + moment(campaign.productionEndDate).format('DD MMM YYYY') 
      }
      return 'Not Set'
    },
    //// Purpose: Sanitize start/end liveDate
    sanitizedLiveDate(campaign) {
      if(campaign.liveStartDate && campaign.liveEndDate) {
        return moment(campaign.liveStartDate).format('DD MMM YYYY')  + ' - ' + moment(campaign.liveEndDate).format('DD MMM YYYY') 
      }
      return 'Not Set'
    },
    // Purpose: Sanitize start/end date
    sanitizedDate(campaign) {
      if(campaign.startDate && campaign.endDate) {
        return moment(campaign.startDate).format('DD MMM YYYY')  + ' - ' + moment(campaign.endDate).format('DD MMM YYYY') 
      }
      return 'Not Set'
    },

    cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      let resultFromApi;
      var checkForResult = setInterval(() => {
        if (resultFromApi !== undefined) {
          clearInterval(checkForResult);
          this.croppedImgB64 = resultFromApi;
          this.cropperDialog = false;
          this.imgUrl = null;
        }
      }, 100);

      if (canvas) {
        const form = new FormData();
        canvas.toBlob((blob) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = function () {
            resultFromApi = reader.result;
          };
        }, "image/png");
      }
    },

    addNewImgToCropper(e) {
      this.uploadedCampaignLogo = Object.values(e.target.files)[0]
      this.imgUrl = URL.createObjectURL(this.uploadedCampaignLogo)
      this.cropperDialog = true
    },
  },
};
</script>
<style scoped>

.cropper {
  height: 600px;
}
</style>