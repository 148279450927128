<template>
    <div style="width: 100%;">
    <!-- Campaisn Status Fiilter-->
    <v-row 
        v-if="showCampaignStatusFilters" 
        alignItems="center" 
        class="d-flex my-5 mx-1" 
        no-gutters
    >
        <v-col
            cols="5"
            class="d-flex align-center"
        >
            <!-- Filter by burst manager -->
            <v-col cols="6">
                <v-combobox
                    v-model="selectedOwners"
                    :items="findOwners"
                    :item-text="item => item.contactCard.firstName+' '+item.contactCard.surname"
                    item-value="id"
                    label="Burst Manager"
                    outlined
                    multiple
                    clearable
                    hide-details
                    return-object
                />
            </v-col>
            <!-- Filter by stakeholders-->
            <v-col cols="6">
                <v-combobox
                    v-model="selectedStakeholdersAndMOs"
                    :items="allStakeholdersAndMOs"
                    :item-text="item => item.fn"
                    item-value="id"
                    label="Search by stakeholders"
                    outlined
                    multiple
                    clearable
                    hide-details
                    return-object
                />
            </v-col>
        </v-col>
       
        <v-col
            cols="7"  
            class="d-flex align-center"
        >
            <!-- Filter by campaig burst workflow status categorie-->
            <v-col cols="5">
                <v-combobox
                    v-model="unCompleteCategoriesToFilter"
                    :items="listOfIssueCategoriesUnComplete"
                    :item-text="item => item.n"
                    item-value="id"
                    label="Search by issue category name"
                    outlined
                    multiple
                    clearable
                    @click.clearable="includeCompletedStatus = 0, unCompleteCategoriesToFilter = []"
                    hide-details
                    return-object
                />
            </v-col>

            <!-- Filter by has at least one burst.workFlowStatys -->
            <v-col cols="auto">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            small 
                            @click="
                                if(includeCompletedStatus === 2) includeCompletedStatus = 0
                                else includeCompletedStatus+=1
                            "
                            :disabled="unCompleteCategoriesToFilter.length === 0"
                            :color="includeCompletedStatus == 1 ? 'primary' : includeCompletedStatus == 2
                                ? '#F464A9' : 'secondary'"
                        >
                            <v-icon style="font-size: 32px;" class="mr-0">{{ 'mdi-check-circle-outline' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ 
                        includeCompletedStatus == 1 ? 'Show categories in Pending' : includeCompletedStatus == 2
                        ? 'Show categories in progress' : 'Show categories completed'
                    }}</span>
                </v-tooltip>
            </v-col>
            
            <!-- Filter by category issue-->
            <v-col cols="5">
                <v-combobox
                    v-model="selectedIssuesForFilter"
                    :items="allIssuesForFilter"
                    :item-text="item => item.title + ' - ' + item.category"
                    item-value="id"
                    label="Search by issue"
                    outlined
                    multiple
                    clearable
                    @click.clearable="includeCompletedStatusIssues = 0, selectedIssuesForFilter = []"
                    hide-details
                    return-object
                />
            </v-col>
            <!-- Filter by has at least one burst.workFlowStatys -->
            <v-col cols="auto">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            small 
                            @click="
                                if(includeCompletedStatusIssues === 2) includeCompletedStatusIssues = 0
                                else includeCompletedStatusIssues+=1
                            "
                            :disabled="selectedIssuesForFilter.length === 0"
                            :color="includeCompletedStatusIssues == 1 ? 'primary' : includeCompletedStatusIssues == 2
                                ? '#F464A9' : 'secondary'"
                        >
                            <v-icon style="font-size: 32px;" class="mr-0">{{ 'mdi-check-circle-outline' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ 
                        includeCompletedStatusIssues == 1 ? 'Show issues in Pending' : includeCompletedStatusIssues == 2
                        ? 'Show issues in progress' : 'Show issues completed'
                    }}</span>
                </v-tooltip>
            </v-col>
        </v-col>
        <!-- Filter categories  issues not complete
        <v-col cols="4" md="4" lg="3" xl="2">
            <v-combobox
                v-model="unCompleteTasksCategoriesToFilter"
                :items="listOfCategoriesWithIssuesNotCompleted"
                :item-text="item => item.n"
                item-value="id"
                label="Search by issues not completed"
                outlined
                multiple
                clearable
                hide-details
                return-object
            />
        </v-col>
        -->
    </v-row>
    
    <v-expansion-panels multiple v-model="panelModel">
        <v-expansion-panel>
            <v-expansion-panel-header hide-actions disabled style="cursor:default">
                <v-row class="d-flex justify-space-between align-center text-center" no-gutters>
                    <h5 class="ml-1" v-if="hasCampaignProcessPriorityPermission">Process</h5>
                    <h5 class="ml-16">Campaign Name</h5>
                    <div class="d-flex ml-auto align-center mr-n1" style="gap: 2.2em;">
                        <h5>K Number</h5>
                        <h5>Manager</h5>
                        <h5>Progress</h5>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    v-on="on"
                                    small 
                                    @click="toggleAll()"
                                    :color="panelModel.length === campaign.length ? 'primary' : 'grey'"
                                >
                                    <v-icon class="mr-0">{{ panelModel.length !== campaign.length ? 'mdi-card-multiple-outline' : 'mdi-card-multiple' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ panelModel.length !== campaign.length ? 'Expand All' : 'Collapse All' }}</span>
                        </v-tooltip>
                    </div>
                </v-row>
            </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel
            v-for="(campaign, i) in filteredCampaigns"
            :key="i"
        >
        <v-expansion-panel-header>
            <ProcessPriority
                v-if="hasCampaignProcessPriorityPermission"
                :campaign="campaign"
                :hasCampaignProcessPriorityPermission="hasCampaignProcessPriorityPermission"/>
            <div
                class="d-flex justify-between align-center mouse-pointer font-weight-bold"
                :style="{ width: `${100}%`, height: `${50}px` }"
            >
                <v-tooltip right :color="campaign.status ? getVuetifyColor(campaign.status.color) : 'white'">
                    <template v-slot:activator="{ attrs, on }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                            :style="{ borderLeft: `7px solid ${campaign.status ? getVuetifyColor(campaign.status.color) : 'white'}`, height: '50px' }"
                        ></span>
                    </template>
                    <span :style="{ color: 'white' }">{{ `Campaign is ${campaign.status ? campaign.status.type : 'none'}` }}</span>
                </v-tooltip>
                <span @click.stop="navigateToCampaignView(campaign.id)">
                    <v-img
                        v-if="campaign.logo"
                        :src="campaign.logo.urlThumb"
                        width="50"
                        height="50"
                        style="cursor: pointer"
                    />
                    <v-img
                        v-else
                        src="d-clamp-holder.jpg"
                        width="50"
                        height="50"
                        style="cursor: pointer"
                    />
                </span>
                <span class="ml-3">{{ campaign.name }}</span>
                <div class="ml-auto font-weight-light d-flex align-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                                @click.stop="clickAction(campaign, 'showCampaignEditFormDialog')"
                            >{{ campaign.kNumber ? campaign.kNumber : 'Not set' }}</span>
                        </template>
                        <span>K Number</span>
                    </v-tooltip>
                    <!-- Dates 
                    <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                            >{{ campaign.productionDate | Moment_DD_MMM_YYYY }}</span>
                        </template>
                        <span>Production Date</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                            >{{ campaign.deliveryDate | Moment_DD_MMM_YYYY }}</span>
                        </template>
                        <span>Delivery Date</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                            >{{ campaign.liveDate | Moment_DD_MMM_YYYY }}</span>
                        </template>
                        <span>Live Date</span>
                    </v-tooltip>
                    -->
                    <v-menu left>
                        <template v-slot:activator="{ on }">
                            <span v-on="on" @click="menuClicked()" class="mr-10" style="cursor: pointer;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="" style="cursor: pointer;">
                                            <v-img
                                                :src='findOwner(campaign) ? findOwner(campaign).contactCard.profilePicture.urlThumb : "contact-placeholder.jpg"'
                                                width="30"
                                                height="30"
                                                style="border: 1px solid #e0e0e0; border-radius: 50%;"
                                                contain
                                            />
                                        </span>
                                    </template>
                                    <span>Burst Managers</span>
                                </v-tooltip>
                            </span>
                        </template>
                        <v-card>
                            <v-card-title class="d-flex justify-center">Burst Managers</v-card-title>
                            <v-card-text>
                                <v-list dense>
                                    <v-list-item 
                                        v-for="(burst, index) in findBursts(campaign)" 
                                        :key="index"
                                        @click.stop="openStakeholderModal(burst, campaign)"
                                    >
                                        <v-list-item-avatar v-if="findBurstOwner(burst)">
                                            <v-img 
                                                :src="
                                                    burst.owner.contactCard.profilePicture
                                                        ? burst.owner.contactCard.profilePicture.urlThumb
                                                        : 'contact-placeholder.jpg'
                                                "
                                                width="30" 
                                                height="40" 
                                                style="border: 1px solid #e0e0e0;"
                                                :rounded="true"
                                            />
                                        </v-list-item-avatar>
                                        <v-list-item-content v-if="findBurstOwner(burst)">
                                            <v-list-item-title>{{ burst.owner.contactCard.firstName+' '+burst.owner.contactCard.surname+' / '+burst.name+' - '+burst.deliveryType.name }}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content v-else>
                                            <v-list-item-title>Click to assign owner to - {{ burst.name +' '+burst.deliveryType.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                            >
                            <!--  -->
                                <v-progress-circular
                                    :value="getProgress(campaign)"
                                    size="30"
                                    width="4"
                                    color="primary"
                                />
                            </span>
                        </template>
                        <span>Progress</span>
                    </v-tooltip>
                </div>
            </div>
            {{ campaign.key }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mb-3">
            <div v-for="(burst, i) in campaign.campaignBursts" v-if="burst.workflowStatus && burst.workflowStatus.id !== 0" :key="i" class="customSpacing">
                <!-- Progress Bar Legend -->
                <v-row class="d-flex align-center justify-space-between mt-3">
                    <div class="d-flex flex-column justify-start" style="max-width: 20%">
                        <div class="d-flex align-center justify-start">
                            <h4>{{ truncate(burst.name, 10) + ' - ' + burst.deliveryType.name }}</h4>
                            <!-- Delete Burst Milestone -->
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        x-small
                                        color="red"
                                        class="d-flex align-center"
                                        @click="openDeleteBurstMilestone(burst)"
                                    >
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete burst milestone</span>
                            </v-tooltip>
                        </div>
                        <!-- Start/End Date Burst -->
                        <div class="d-flex align-center text-caption my-2 flex-start" style="gap: 1em;">
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                    >{{ getBurstStartDate(burst) | Moment_DD_MMM_YYYY }}</span>
                                </template>
                                <span>Start Date</span>
                            </v-tooltip>
                            <v-divider vertical></v-divider>
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                    >{{ getBurstEndDate(burst) | Moment_DD_MMM_YYYY }}</span>
                                </template>
                                <span>End Date</span>
                            </v-tooltip>
                        </div>
                    </div>
                    
                    <div class="d-flex align-center justify-end" style="max-width: 75%">
                        <div v-for="item in [{name: 'Completed', color: '#499F68'}, {name: 'In progress', color: '#F464A9'}, {name: 'Pending', color: '#4AA6D5'}]">
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="d-flex align-center text-caption mr-4"
                                    >
                                        <div 
                                            :style="{ backgroundColor: item.color, width: '15px', height: '15px', borderRadius: '50%' }"
                                            class="mx-2"
                                            ></div>
                                        {{ item.name }}
                                    </div>
                                </template>
                                <span>{{ item.name }}</span>
                            </v-tooltip>
                        </div>
                        <!-- Progress Bar Legend 
                        <div v-for="category in burst.workflowStatus.categories" :key="category.id">
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="d-flex align-center text-caption mr-4"
                                        @click="openIssue(category, burst)"
                                    >
                                        <div 
                                            :style="{ backgroundColor: filterColor(category.status), width: '15px', height: '15px', borderRadius: '50%' }"
                                            class="mx-2"
                                            ></div>
                                        {{ category.name }}
                                    </div>
                                </template>
                                <span>{{ category.name }}</span>
                            </v-tooltip>
                        </div>
                        -->
                         <!-- + btn icon -->
                    <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                :disabled="burst.workflowStatus.categories.length === 0 || !$store.state.Permissions.campaignMilestoneEdit"
                                fab
                                x-small
                                color="primary"
                            >
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    @click="createIssue(burst)"
                                >mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add task</span>
                    </v-tooltip>
                    </div>
                   
                </v-row>
                <!-- Epic Progress Bar display -->
                <div class="d-flex align-center subIssues mt-5" style="width: 100%; height: 25px; border-radius: 10px" v-if="burst.workflowStatus.categories.length !== 0"> 
                    <v-tooltip top v-for="category, index in burst.workflowStatus.categories" v-if="burst.workflowStatus.id !== 0" :key="category.id">
                        <template v-slot:activator="{ on, attrs }" >
                            <v-hover v-slot="{ hover }" >
                                <!-- Sub Issue of an epic display-->
                                <div 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="openIssue(index, category, burst, campaign)"
                                    class="subIssue text-center"
                                    :style="{
                                        backgroundColor: filterColor(category.s),
                                        width: `${100 / burst.workflowStatus.categories.length}%`,
                                        opacity: hover ? 0.5 : 1,
                                        height: '25px',
                                        
                                    }">
                                    <span class="text-caption">{{ truncate(category.n, 10) }}</span>
                                </div>
                            </v-hover>
                        </template>
                        <span>{{ category.n }}</span>
                    </v-tooltip>
                </div>
                <!-- No epic progress bar display -->
                <div v-else class="text-center mt-3">
                    <v-divider class="mb-3"></v-divider>
                    Attach screens to {{'`'+campaign.campaignBursts[i].name + ' - ' + campaign.campaignBursts[i].deliveryType.name+'`' }} to see the progress bar.
                </div>
            </div>
            <div v-else class="text-center mt-3">
                    <v-divider class="mb-3"></v-divider>
                    No profile milestone attached to {{'`'+campaign.campaignBursts[i].name + ' - ' + campaign.campaignBursts[i].deliveryType.name+'`' }}.
            </div>
        </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <!--
    <v-row class="d-flex justify-end mt-5 align-center">
        <div></div>
        <v-select
            v-model="pagination.rowsPerPage"
            :items="rowsPerPageItems"
            label="Rows per page"
            class="mr-5"
            style="max-width: 100px"
            @change="rowsPerPageChange($event)"
            flat
        ></v-select>
        <v-pagination
            v-model="pagination.page"
            :length="Math.ceil(filteredCampaigns.length / pagination.rowsPerPage)"
            :total-visible="5"
            circle
            color="primary"
            class="ml-5"
            @input="pageChange($event)"
        ></v-pagination>
        
    </v-row>
    -->
    {{/** Misc elements Loader/Persistent snackbar */}}
    <v-overlay :value="overlay" class="text-center justify-center">
        <v-progress-circular
            indeterminate
            size="64"
            class="ml-3"
        ></v-progress-circular>
        <br/>
        <div class="mt-2">Fetching campaign milestones...</div>
    </v-overlay>
    <IssueModal
        v-if="openIssueModal"
        :selectedIssue="selectedIssue"
        :selectedBurst="selectedBurst"
        :selectedCampaign="selectedCampaign"
        :issueIndex="issueIndex"
        :openIssueModal="openIssueModal"
    />
    <CreateModal 
        v-if="createIssueModal"
        :createIssueModal="createIssueModal"
        :selectedBurst="selectedBurst"
        :selectedCampaign="selectedCampaign"
        :categories="categories"
        :allDIssues="allDIssues"
    />
    <v-row>
        <!-- Delete pop dialog -->
        <v-dialog
            v-if="openDeleteIssueModal && selectedBurstToDelete"
            v-model="openDeleteIssueModal"
            width="600"
        >
            <v-card class="px-6 pt-6 text-center" >
                Are you sure you want to delete the milestone profile from
                {{ JSON.stringify(selectedBurstToDelete.name) }}?        
                <v-card-actions class="pb-6 pt-4">
                    <v-col
                        cols="12"
                        class="text-center ma-0 pa-0"
                    >
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="confirmDeleteBurstMilestone()">
                            Confirm
                        </v-btn>
                        <v-btn
                            color="red"
                            @click="openDeleteIssueModal = false">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <!-- Campaign Stakeholder Dialog -->
    <v-dialog
      v-model="showStakeholdersDialog"
      width="950"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
            Assign owner to {{ selectedBurst ? selectedBurst.name : 'burst' }}
        </v-card-title>
        <CampaignStakeholders
          v-if="showStakeholdersDialog && selectedCampaign"
          :campaignId="selectedCampaign.id"
          :burstId="selectedBurst ? selectedBurst.id : null"
          :ownerId="selectedBurst ? (selectedBurst.owner ? selectedBurst.owner.id : selectedBurst.id) : null"
        />
      </v-card>
    </v-dialog>
    </div>
</template>
<script>
    // Controllers 
    import IssueTask from '@/services/controllers/IssueTask'
    // Mixins
    import helpersConcatText from '@/mixins/helpers/concatText'
    import vuetifyColors from '@/mixins/vuetify/colors'
    import collectionSort from '@/mixins/collection/sort'
    import collectionSearch from '@/mixins/collection/search'
    // External libraries
    import moment from 'moment'
    /** Components */
    import IssueModal from '@/components/campaigns/modals/ProgressIssueModal'
    import CreateModal from '@/components/campaigns/modals/ProgressCreateIssue'
    import CampaignStakeholders from '@/components/campaign/Stakeholders'
    import ProcessPriority from '@/components/campaigns/ProcessPriority'
    // VUEX
    import { mapState } from 'vuex'
    
    export default {
        components: {
            IssueModal,
            CreateModal,
            CampaignStakeholders,
            ProcessPriority
        },
        mixins: [
            helpersConcatText,
            vuetifyColors
        ],
        name: 'ProgressView',
        filters: {
            Moment_DD_MMM_YYYY: function (date) {
                return moment(date).format('DD MMM YYYY')
            },
        },
        props: {
            campaign: {
                type: Array,
                default: []
            },
            showCampaignStatusFilters: {
                type: Boolean,
                default: false
            },
            includeBursts: {
                type: Boolean,
                default: false
            },
            includeStatus: {
                type: Boolean,
                default: false
            },
            includeArchived: {
                type: Boolean,
                default: false
            },
            campaignStatus: {
                type: String,
                default: null
            },
            campaignSortBy: {
                type: String,
                default: 'name'
            },
            campaignOrderBy: {
                type: String,
                default: 'asc'
            },
            searchCampaign: {
                type: String,
                default: ''
            },
            notificationCampaignId: {
                type: Number,
                default: null
            },
            notificationBurstId: {
                type: Number,
                default: null
            },
            notificationIssueId: {
                type: Number,
                default: null
            },
            includeCompletedStatusGlobal: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            // Data Loaded
            overlay: false,
            // Get Data from JIRA vars
            // Campaign var stored data about the cam[aign with milestones
            //campaign: [],
            epicIssues: [],
            epicIssueSubTasks: [],
            //** Modals */
            // Issue modal
            openIssueModal: false,
            selectedIssue: {},
            selectedBurst: {},
            // Add Issue Modal
            createIssueModal: false,
            // Delete Issue Modal
            openDeleteIssueModal: false,
            // Issues
            //categories: [],
            // Pagination
            pagination: {
                rowsPerPage: 10,
                page: 1
            },
            // Rows per page
            rowsPerPageItems: [10, 25, 50, 100],
            // Expansion
            panelModel: [],
            // Managers
            menu: false,
            managers: [],
            selectedOwners: [],
            // Stakeholders
            showStakeholdersDialog: false,
            selectedCampaign: {},
            // Original list of categories & issues
            categories: [],
            allDIssues: [],
            selectedBurstToDelete: null,
            // Issue index
            issueIndex: null,
            foundIssue: null,
            foundBurst: null,
            // Advanced filters
            unCompleteCategoriesToFilter: [],
            includeCompletedStatus: 0,
            unCompleteTasksCategoriesToFilter: [],
            selectedStakeholdersAndMOs: [],
            selectedIssuesForFilter: [],
            includeCompletedStatusIssues: 0,

            // Search Params
            searchParams: [
                { name: 'name', weight: 2 },
                { name: 'campaignStakeholders.stakeholder.name', weight: 0.5 },
                { name: 'deliveryType.name', weight: 0.3 },
                { name: 'kNumber', weight: 0.25 },
                { name: 'startDate', weight: 0.01 },
                { name: 'endDate', weight: 0.01 }
            ],
        }),
        // destroy the event listeners
        beforeDestroy() {
            console.log("ProgressView destroyed")
            this.$root.$off('updateOrDeleteCampaign')
            this.$root.$off('updateBurstOwner')
            this.$root.$off('updateBurstAfterDeleteIssue')
        },
        async created(){
            // Get all categories
            await IssueTask.getAllDCategories()
                .then((res) => {
                    this.categories = res.data
                })
                .catch((err) => {
                    console.log(err)
                })
            // Get All issues
            await IssueTask.getAllDIssues()
                .then((res) => {
                    this.allDIssues = res.data
                })
                .catch((err) => {
                    console.log(err)
                })
            this.$nextTick()
            /* Get all campaigns with milestones
            await CampaignController.getAllCampaigns(this.includeBursts,this.includeStatus,this.includeArchived).then((res)=>{  
                this.campaign = res.data
                console.log('Data from ProgressView')
                console.log(this.campaign)
                this.addCampaignStatus()
                /* Append every campaignBursts from this.campaign to this.campaigns
                this.campaigns.forEach((campaign) => {
                    this.campaign.forEach((campaign2) => {
                        if (campaign.id === campaign2.id) {
                            campaign.campaignBursts = campaign2.campaignBursts
                        }
                    })
                }) 
                // Set Overlay to false
                this.overlay = false
                this.$nextTick()
            }).catch((err) => {
                this.overlay = false
                console.log(err)
            }) */
            // Global Functions to update the campaign after modifing a milestone
            this.$root.$on('updateOrDeleteCampaign', (action, item) => {
                this.campaign.forEach((campaign) => {
                    campaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.id === item.id) {
                            if (action === 'update') {
                                campaignBurst.workflowStatus = item;
                            } else if (action === 'delete') {
                                campaignBurst.workflowStatus = { id: 0 }
                            }
                        }
                    })
                })
                this.$nextTick()
            })
            // Global function to update the burst owner in a burst
            this.$root.$on('updateBurstOwner', (owner, burstId) => {
                // Find the burst in this.campaigns where burstId === burst.id
                let foundBurst = this.campaign.find((campaign) => {
                    return campaign.campaignBursts.find((burst) => {
                        if (burst.id === burstId) {
                            if(burst.owner === null){
                                burst.owner = owner
                            } else {
                            // Assign every property from owner to burst.owner, but keep the burst.owner properties that are not in the owner object
                                for (var key in owner) {
                                    burst.owner[key] = owner[key]
                                }
                            }
                            return true
                        }
                    })
                })
                // Replace the the burst in this.campaign where burstId === burst.id with the foundBurst using split and findIndex
                let campaignIndex = this.campaign.findIndex((campaign) => {
                    return campaign.campaignBursts.findIndex((burst) => {
                        if (burst.id === burstId) {
                            return true
                        }
                    })
                })
                this.campaign.splice(campaignIndex, 1, foundBurst)

                this.$nextTick()
            })
            // Global function to update a burst after deleting an issue
            this.$root.$on('updateBurstAfterDeleteIssue', (burstId, data) => {
                // Iterate through this.campaigs and through each campaign.campaignBursts and find the burst where burst.id === burstId
                let foundCampaign = this.campaign.find((campaign) => {
                    return campaign.campaignBursts.find((burst) => {
                        if (burst.id === burstId) {
                            return true
                        }
                    })
                })
                // Find the index of foundCampaign in this.campaigns
                let campaignIndex = this.campaign.findIndex((campaign) => {
                    if (campaign.id === foundCampaign.id) {
                        return true
                    }
                })
                // Find the index of the burst in foundCampaign.campaignBursts where burst.id === burstId
                let burstIndex = foundCampaign.campaignBursts.findIndex((burst) => {
                    if (burst.id === burstId) {
                        return true
                    }
                })
                // Update this.campaigns[index].campaignBursts[index].workflowstatus with the data from the event using splice
                this.campaign[campaignIndex].campaignBursts[burstIndex].workflowStatus = data
                this.$nextTick()
            })
            // Get username from localstorage, and check if theres a entry in findOwners with the username, if there is, then set the selectedOwners to the entry
            /* Automatically select the owner in the filter by owner combobox 
            let username = localStorage.getItem("username")
            if (username) {
                let foundOwner = this.findOwners.find((owner) => {
                    if (owner.contactCard.email === username) return true
                })
                if (foundOwner) this.selectedOwners.push(foundOwner)
            }*/

            
        },
        provide: function () {
            return {
                // Modals
                closeIssue: this.closeIssue,
            }
        },
        inject: ['navigateToCampaignView'],
        computed: {
            hasCampaignProcessPriorityPermission() {
                return this.$store.state.Permissions.reportingPriority
            },
            ...mapState('UI', ['showCampaignEditFormDialog']),

            getBurstStartDate() {
                return (burst) => {
                    if (burst.startDate) {
                        return burst.startDate
                    } else {
                        let campaign = this.campaign.find((campaign) => {
                            return campaign.campaignBursts.find((campaignBurst) => {
                                if (campaignBurst.id === burst.id) {
                                    return true
                                }
                            })
                        })
                        if (campaign) {
                            return moment(campaign.startDate).add(10, 'days').format('YYYY-MM-DD')
                        }
                    }
                }
            },

            getBurstEndDate() {
                return (burst) => {
                    if (burst.endDate) {
                        return burst.endDate
                    } else {
                        let campaign = this.campaign.find((campaign) => {
                            return campaign.campaignBursts.find((campaignBurst) => {
                                if (campaignBurst.id === burst.id) {
                                    return true
                                }
                            })
                        })
                        if (campaign) {
                            return moment(campaign.endDate).add(10, 'days').format('YYYY-MM-DD')
                        }
                    }
                }
            },    

            findOwner() {
                return (selectedCampaign) => {
                    // Find a a burst owner exists in one of the campaignBursts ina  selectedCampaign and return the owner object, if there's ultiple owners, return the one that first found with a profilePicture
                    var selectedOwner = null
                    selectedCampaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.owner) {
                            if (campaignBurst.owner.contactCard.profilePicture) {
                                selectedOwner = campaignBurst.owner
                                selectedOwner.burstId = campaignBurst.id
                                selectedOwner.burstName = campaignBurst.name
                                selectedOwner.deliveryType = campaignBurst.deliveryType.name
                            }
                        }
                    })
                    return selectedOwner
                }
            },

            // Function to find all the owners in every campaignBursts in a campaign from each campaign in this.campaigns and return them in an array
            findOwners() {
                let owners = []
                    this.campaign.forEach((campaign) => {
                        campaign.campaignBursts.forEach((campaignBurst) => {
                            if (campaignBurst.owner) {
                                let owner = campaignBurst.owner
                                owner.burstId = campaignBurst.id
                                owner.burstName = campaignBurst.name
                                owner.deliveryType = campaignBurst.deliveryType.name
                                owners.push(owner)
                            }
                        })
                    })
                    return owners
            },

            listOfIssueCategoriesUnComplete() {
                let categories = []
                this.campaign.forEach((campaign) => {
                    campaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.workflowStatus) {
                            campaignBurst.workflowStatus.categories.forEach((category) => {
                                if (category.s !== 0) {
                                    categories.push(category)
                                }
                            })
                        }
                    })
                })
                // Remove dublicated
                categories = categories.filter((category, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === category.id
                    ))
                )
                //console.log(categories)
                return categories
            },

            listOfCategoriesWithIssuesNotCompleted() {
                let categories = []
                this.campaign.forEach((campaign) => {
                    campaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.workflowStatus) {
                            campaignBurst.workflowStatus.categories.forEach((category) => {
                                // Look for dIs in category, its an array of issues
                                if (category.dIs.length > 0) {
                                    // Look for issues that are not completed
                                    category.dIs.forEach((issue) => {
                                        if (issue.s !== 1) {
                                            categories.push(category)
                                        }
                                    })
                                }
                            })
                        }
                    })
                })
                // Remove dublicated
                categories = categories.filter((category, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === category.id
                    ))
                )
                //console.log(categories)
                return categories
            },

            allStakeholdersAndMOs() {
                let stakeholdersSet = new Set();

                this.campaign.forEach((campaign) => {
                    if (campaign.campaignStakeholders) {
                        campaign.campaignStakeholders.forEach((stakeholder) => {
                            if (stakeholder && stakeholder.stakeholder) {
                                stakeholdersSet.add(JSON.stringify({
                                    ad: {
                                        cc: stakeholder.stakeholder.address?.countryCode || ''
                                    },
                                    fn: stakeholder.stakeholder.friendlyName || '',
                                    id: stakeholder.stakeholder.id || '',
                                    n: stakeholder.stakeholder.name || ''
                                }));
                            }
                        });
                    }

                    campaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.campaignBurstScreens) {
                            campaignBurst.campaignBurstScreens.forEach((screen) => {
                                if (screen && screen.screen && screen.screen.mo) {
                                    stakeholdersSet.add(JSON.stringify(screen.screen.mo));
                                }
                            });
                        }
                    });
                });

                const stakeholdersArray = Array.from(stakeholdersSet).map((stakeholder) => JSON.parse(stakeholder));
                return stakeholdersArray;
            },

            allIssuesForFilter() {
                let issuesSet = new Set();

                // iterate through this.campaigns and through each campaign.campaignBursts, then through each campaignBurst.campaignBurstScreens and push .workflowStatus.categories.dIs to issues using set
                this.campaign.forEach((campaign) => {
                    campaign.campaignBursts.forEach((campaignBurst) => {
                        if (campaignBurst.workflowStatus) {
                            campaignBurst.workflowStatus.categories.forEach((category) => {
                                if (category.dIs.length > 0) {
                                    category.dIs.forEach((issue) => {
                                        issuesSet.add(JSON.stringify(issue))
                                    })
                                }
                            })
                        }
                    })
                })

                let issues = Array.from(issuesSet).map((issue) => JSON.parse(issue));
                // sort the array by category name alphabetically
                issues.sort((a, b) => {
                    if (a.category < b.category) {
                        return -1;
                    }
                    if (a.category > b.category) {
                        return 1;
                    }
                    return 0;
                });
                return issues;
            },

            // Campaign filters
            filteredCampaigns() {
                let data = [];

                if (this.campaign && this.campaign.length > 0) {
                    switch (this.campaignStatus) {
                    case 'live':
                        data = this.liveCampaigns;
                        break;

                    case 'pending':
                        data = this.pendingCampaigns;
                        break;

                    case 'completed':
                        data = this.completedCampaigns;
                        break;

                    default:
                        data = this.campaign;
                        break;
                    }

                    // Apply filtering logic from the first function
                    if (this.selectedOwners.length > 0) {
                        data = data.filter((campaign) => {
                            return campaign.campaignBursts.find((campaignBurst) => {
                            if (campaignBurst.owner) {
                                return this.selectedOwners.find((owner) => {
                                if (owner.id === campaignBurst.owner.id) {
                                    return true;
                                }
                                });
                            }
                            });
                        });
                    }

                    // If selectedStakeholdersAndMOs is not empty, then filter the campaigns that have the selectedStakeholdersAndMOs in the campaignStakeholders or in the campaignBurstScreens.screen
                    if (this.selectedStakeholdersAndMOs.length > 0) {
                        data = data.filter((campaign) => {
                            // Check if the campaign has campaignStakeholders
                            if (campaign.campaignStakeholders) {
                                // Check if the campaignStakeholders has the selectedStakeholdersAndMOs
                                const hasSelectedStakeholder = campaign.campaignStakeholders.some((stakeholder) => {
                                    return stakeholder.stakeholder && this.selectedStakeholdersAndMOs.some((selectedStakeholder) => {
                                        return stakeholder.stakeholder.id === selectedStakeholder.id;
                                    });
                                });

                                if (hasSelectedStakeholder) {
                                    return true;
                                }
                            }

                            // Check if the campaign has campaignBursts
                            if (campaign.campaignBursts) {
                                // Check if the campaignBursts has campaignBurstScreens
                                const hasSelectedStakeholderInScreen = campaign.campaignBursts.some((campaignBurst) => {
                                    if (campaignBurst.campaignBurstScreens) {
                                        // Check if the campaignBurstScreens has the selectedStakeholdersAndMOs
                                        return campaignBurst.campaignBurstScreens.some((screen) => {
                                            if (screen.screen && screen.screen.mo) {
                                                return this.selectedStakeholdersAndMOs.some((selectedStakeholder) => {
                                                    return screen.screen.mo.id === selectedStakeholder.id;
                                                });
                                            }
                                        });
                                    }
                                });

                                if (hasSelectedStakeholderInScreen) {
                                    return true;
                                }
                            }

                            return false;
                        });
                    }

                    if (!this.includeCompletedStatusGlobal) {
                        // filter to include campaign where every campaignBurst.workflowStatus.categories is completed
                        data = data.filter((campaign) => {
                            for(let i=0; i < campaign.campaignBursts.length; i++) {
                                if(campaign.campaignBursts[i].workflowStatus.categories.length > 0) {
                                    for(let j=0; j < campaign.campaignBursts[i].workflowStatus.categories.length; j++) {
                                        if(campaign.campaignBursts[i].workflowStatus.categories[j].s !== 1) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        })
                    }


                    if (this.includeCompletedStatus !== 0 && this.unCompleteCategoriesToFilter.length > 0) {
                        const filterCompleted = this.includeCompletedStatus === 1 ? 4 : this.includeCompletedStatus === 2 ? 3 : 2;
                        //console.log(filterCompleted)
                        data = data.filter((campaign) => {
                            return campaign.campaignBursts.every((campaignBurst) => {
                                return campaignBurst.workflowStatus.categories.every((category) => {
                                    return !(
                                        category.s !== filterCompleted && // Check for specific completion status
                                        this.unCompleteCategoriesToFilter.some(
                                            (categoryToFilter) => categoryToFilter.id === category.id
                                        )
                                    );
                                });
                            });
                        });
                    } else if (this.includeCompletedStatus === 0 && this.unCompleteCategoriesToFilter.length > 0) {
                        data = data.filter((campaign) => {
                            return campaign.campaignBursts.every((campaignBurst) => {
                                return campaignBurst.workflowStatus.categories.every((category) => {
                                    return !(
                                        category.s !== 1 && // Assuming 0 represents incomplete status
                                        this.unCompleteCategoriesToFilter.some(
                                            (categoryToFilter) => categoryToFilter.id === category.id
                                        )
                                    );
                                });
                            });
                        });
                    }

                    // Write a filter similar to the one above, but for issues using includeCompletedStatusIssues and selectedIssuesForFilter
                    if (this.includeCompletedStatusIssues !== 0 && this.selectedIssuesForFilter.length > 0) {
                        const filterCompleted = this.includeCompletedStatusIssues === 1 ? 4 : this.includeCompletedStatusIssues === 2 ? 3 : 2;
                        //console.log(filterCompleted)
                        data = data.filter((campaign) => {
                            return campaign.campaignBursts.every((campaignBurst) => {
                                return campaignBurst.workflowStatus.categories.every((category) => {
                                    return category.dIs.every((issue) => {
                                        return !(
                                            issue.s !== filterCompleted && // Check for specific completion status
                                            this.selectedIssuesForFilter.some(
                                                (issueToFilter) => issueToFilter.id === issue.id
                                            )
                                        );
                                    });
                                });
                            });
                        });
                    } else if (this.includeCompletedStatusIssues === 0 && this.selectedIssuesForFilter.length > 0) {
                        data = data.filter((campaign) => {
                            return campaign.campaignBursts.every((campaignBurst) => {
                                return campaignBurst.workflowStatus.categories.every((category) => {
                                    return category.dIs.every((issue) => {
                                        return !(
                                            issue.s !== 1 && // Assuming 0 represents incomplete status
                                            this.selectedIssuesForFilter.some(
                                                (issueToFilter) => issueToFilter.id === issue.id
                                            )
                                        );
                                    });
                                });
                            });
                        });
                    }

                    // Apply sorting and searching logic
                    data = collectionSort(collectionSearch(data, this.searchParams, this.searchCampaign, 0.25), this.campaignSortBy, this.campaignOrderBy)

                    // Filter each campaign burst.workflowStatus.categories to show only the categories that are in the unCompleteCategoriesToFilter array and are not completed
                    return data;
                }

                return [];
            },

            liveCampaigns() {
                if(this.campaign && this.campaign.length > 0) return this.campaign.filter(campaign => campaign.status.type === 'live');
                return []
            },
            liveCampaignsCount() {
                if(this.campaign && this.campaign.length > 0) return collectionSort(collectionSearch(this.liveCampaigns, this.searchParams, this.searchCampaign, 0.25), this.campaignSortBy, this.campaignOrderBy).length
                return 0
            },
            pendingCampaigns() {
                if(this.campaign && this.campaign.length > 0) return this.campaign.filter(campaign => campaign.status.type === 'pending')
                return []
            },
            pendingCampaignsCount() {
                if(this.campaign && this.campaign.length > 0) return collectionSort(collectionSearch(this.pendingCampaigns, this.searchParams, this.searchCampaign, 0.25), this.campaignSortBy, this.campaignOrderBy).length
                return 0
            },
            completedCampaigns() {
                if(this.campaign && this.campaign.length > 0) return this.campaign.filter(campaign => campaign.status.type === 'completed')
                return []
            },
        },
        watch: {
            unCompleteCategoriesToFilter: {
                handler: function (val) {
                    console.log(val)
                },
                deep: true,
            },
            selectedOwners: {
                handler: function (val) {
                    this.$emit('checkSelectedOwner', val)
                },
                deep: true,
            },
            campaign: {
                handler: function (val) {
                    try{
                        if(val.length >= 1 && this.$store.state.UI.issueTaskModal) {
                            if (this.notificationCampaignId && this.notificationBurstId && this.notificationIssueId) {
                                this.$emit('filterToTotal')
                                this.$emit('changeViewTypeProgress')
                                setTimeout(() => {
                                    this.jumpToIssue()
                                }, 100);
                            }
                        } else if (val.length >= 0) {
                            this.getFiltersFromLocalStorage()
                        }
                    } catch(err) {
                        console.log(err)
                    } finally {
                        this.$store.commit('UI/SET_ISSUE_TASK_MODAL', false)
                    }
                },
                deep: true,
            },
            // Watch for a global state variable
            '$store.state.UI.issueTaskModal' (newValue, oldValue) {
                // if the new value is not null, then open the issue modal
                if(newValue === true) {
                    try{
                        this.$emit('filterToTotal')
                        this.$emit('changeViewTypeProgress')
                        setTimeout(() => {
                            this.jumpToIssue()
                        }, 100);
                    } catch(err) {
                        console.log(err)
                    } finally {
                        this.$store.commit('UI/SET_ISSUE_TASK_MODAL', false)
                    }
                }
            },  
            selectedOwners: {
                handler: function (val) {
                    const selectedOwners = val.map((owner) => owner.id);
                    localStorage.setItem('selectedOwners', JSON.stringify(selectedOwners))
                },
                deep: true,
            },
            selectedStakeholdersAndMOs: {
                handler: function (val) {
                    const selectedStakeholdersAndMOs = val.map((stakeholder) => stakeholder.id);
                    localStorage.setItem('selectedStakeholdersAndMOs', JSON.stringify(selectedStakeholdersAndMOs))
                },
                deep: true,
            },
            unCompleteCategoriesToFilter: {
                handler: function (val) {
                    const unCompleteCategoriesToFilter = val.map((category) => category.id);
                    localStorage.setItem('unCompleteCategoriesToFilter', JSON.stringify(unCompleteCategoriesToFilter))
                },
                deep: true,
            },
            includeCompletedStatus: {
                handler: function (val) {
                    localStorage.setItem('includeCompletedStatus', JSON.stringify(val))
                },
                deep: true,
            },
            selectedIssuesForFilter: {
                handler: function (val) {
                    if(val.length >= 0) {
                    const selectedIssuesForFilter = val.map((issue) => issue.id);
                    localStorage.setItem('selectedIssuesForFilter', JSON.stringify(selectedIssuesForFilter))
                    }
                },
                deep: true,
            },
            includeCompletedStatusIssues: {
                handler: function (val) {
                    localStorage.setItem('includeCompletedStatusIssues', JSON.stringify(val))
                },
                deep: true,
            },

        },
        methods: {
            async getFiltersFromLocalStorage() {
                // Get selectedOwners from localstorage
                let selectedOwners = localStorage.getItem('selectedOwners')
                if (selectedOwners !== '' && selectedOwners !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    // selectedOwners includes only the ids of the owners, so we need to find the owners in findOwners and push them to selectedOwners
                    selectedOwners = JSON.parse(selectedOwners)
                    //console.log(selectedOwners)
                    selectedOwners.forEach((owner) => {
                        let foundOwner = this.findOwners.find((owner2) => {
                            if (owner2.id === owner) return true
                        })
                        // Check for dublicates
                        if (foundOwner) {
                            let found = this.selectedOwners.find((owner) => {
                                if (owner.id === foundOwner.id) return true
                            })
                            if (!found) this.selectedOwners.push(foundOwner)
                        }

                    })
                }

                // Get selectedStakeholdersAndMOs from localstorage
                let selectedStakeholdersAndMOs = localStorage.getItem('selectedStakeholdersAndMOs')
                if (selectedStakeholdersAndMOs !== '' && selectedStakeholdersAndMOs !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    
                    // selectedStakeholdersAndMOs includes only the ids of the stakeholders and MOs, so we need to find the stakeholders and MOs in allStakeholdersAndMOs and push them to selectedStakeholdersAndMOs
                    selectedStakeholdersAndMOs = JSON.parse(selectedStakeholdersAndMOs)
                    selectedStakeholdersAndMOs.forEach((stakeholder) => {
                        let foundStakeholder = this.allStakeholdersAndMOs.find((stakeholder2) => {
                            if (stakeholder2.id === stakeholder) return true
                        })
                       // check for dublicated
                        if (foundStakeholder) {
                            let found = this.selectedStakeholdersAndMOs.find((stakeholder) => {
                                if (stakeholder.id === foundStakeholder.id) return true
                            })
                            if (!found) this.selectedStakeholdersAndMOs.push(foundStakeholder)
                        }
                    })

                }

                // Get selectedIssuesForFilter from localstorage
                let selectedIssuesForFilterLocal = localStorage.getItem('selectedIssuesForFilter')
                if (selectedIssuesForFilterLocal !== '' && selectedIssuesForFilterLocal !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    // selectedIssuesForFilter includes only the ids of the issues, so we need to find the issues in allDIssues and push them to selectedIssuesForFilter
                    selectedIssuesForFilterLocal = JSON.parse(selectedIssuesForFilterLocal)
                    selectedIssuesForFilterLocal.forEach((issue) => {
                        let foundIssue = this.allIssuesForFilter.find((issue2) => {
                            if (issue2.id === issue) return true
                        })
                        // check dublicates
                        if(foundIssue) {
                            let found = this.selectedIssuesForFilter.find((issue) => {
                                if (issue.id === foundIssue.id) return true
                            })
                            if (!found) this.selectedIssuesForFilter.push(foundIssue)
                        }
                    })
                }

                // Get unCompleteCategoriesToFilter from localstorage
                let unCompleteCategoriesToFilter = localStorage.getItem('unCompleteCategoriesToFilter')
                if (unCompleteCategoriesToFilter !== '' && unCompleteCategoriesToFilter !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    // unCompleteCategoriesToFilter includes only the ids of the categories, so we need to find the categories in listOfIssueCategoriesUnComplete and push them to unCompleteCategoriesToFilter
                    unCompleteCategoriesToFilter = JSON.parse(unCompleteCategoriesToFilter)
                    unCompleteCategoriesToFilter.forEach((category) => {
                        let foundCategory = this.listOfIssueCategoriesUnComplete.find((category2) => {
                            if (category2.id === category) return true
                        })
                        // check dublicates
                        if(foundCategory) {
                            let found = this.unCompleteCategoriesToFilter.find((category) => {
                                if (category.id === foundCategory.id) return true
                            })
                            if (!found) this.unCompleteCategoriesToFilter.push(foundCategory)
                        }
                    })
                }

                // Get unCompleteTasksCategoriesToFilter from localstorage
                let unCompleteTasksCategoriesToFilter = localStorage.getItem('unCompleteTasksCategoriesToFilter')
                if (unCompleteTasksCategoriesToFilter !== '' && unCompleteTasksCategoriesToFilter !== null && unCompleteTasksCategoriesToFilter !== undefined) {
                    this.$emit('showCampaignStatusFiltersChange')
                    // unCompleteTasksCategoriesToFilter includes only the ids of the categories, so we need to find the categories in listOfCategoriesWithIssuesNotCompleted and push them to unCompleteTasksCategoriesToFilter
                    unCompleteTasksCategoriesToFilter = JSON.parse(unCompleteTasksCategoriesToFilter)
                    unCompleteTasksCategoriesToFilter.forEach((category) => {
                        let foundCategory = this.listOfCategoriesWithIssuesNotCompleted.find((category2) => {
                            if (category2.id === category) return true
                        })
                        // check dublicates
                        if(foundCategory) {
                            let found = this.unCompleteTasksCategoriesToFilter.find((category) => {
                                if (category.id === foundCategory.id) return true
                            })
                            if (!found) this.unCompleteTasksCategoriesToFilter.push(foundCategory)
                        }
                    })
                }

                // Get includeCompletedStatus from localstorage
                let includeCompletedStatus = localStorage.getItem('includeCompletedStatus')
                if (includeCompletedStatus !== '' && includeCompletedStatus !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    this.includeCompletedStatus = JSON.parse(includeCompletedStatus)
                }

                // Get includeCompletedStatusIssues from localstorage
                let includeCompletedStatusIssues = localStorage.getItem('includeCompletedStatusIssues')
                if (includeCompletedStatusIssues !== '' && includeCompletedStatusIssues !== null) {
                    this.$emit('showCampaignStatusFiltersChange')
                    this.includeCompletedStatusIssues = JSON.parse(includeCompletedStatusIssues)
                }

            },
            

            jumpToIssue() {
                // find campaign by id
                    //console.log("CampaignID: " +parseInt(this.$store.state.UI.campaignId))
                    let foundCampaign = this.campaign.find((campaign) => {
                        return campaign.id === parseInt(this.$store.state.UI.campaignId)
                    })
                    if(foundCampaign == null)
                    {
                        //localStorage.setItem('campaignStatus', 'all')
                        // re execute function
                        //
                        //this.$root.$emit('snackbarWarning', "Please move to \"Total\" campaign view and try again.")  
                        //localStorage.setItem('campaignStatus', 'all')
                        return
                    }
                    //console.log("Found: " + (foundCampaign != undefined))
                    // find burst by id
                    //console.log("BurstId: " +parseInt(this.$store.state.UI.campaignBurstId))
                    let foundBurst = foundCampaign.campaignBursts.find((burst) => {
                        return burst.id === parseInt(this.$store.state.UI.campaignBurstId)
                    })
                    //console.log("Found: " + (foundCampaign != undefined))
                    // find issue by id in burst
                    //console.log("IssueId: " +parseInt(this.$store.state.UI.issueId))
                    if(foundBurst == null || foundBurst.workflowStatus == null)
                    {
                        //console.log("Trying to debug this error 1");
                        return;   
                    }
                    let foundIssue = foundBurst.workflowStatus.categories.find((issue) => {
                        // return the value where issue.dIssues.id === this.notificationIssueId
                        return issue.dIs.find((dIssue) => {
                            return dIssue.id === parseInt(this.$store.state.UI.issueId)
                        })
                    })
                    //console.log("Found: " + (foundCampaign != undefined))
                    // Find index of the issue
                    let issueIndex = foundBurst.workflowStatus.categories.findIndex((issue) => {
                        // return the value where issue.dIssues.id === this.notificationIssueId
                        return issue.dIs.find((dIssue) => {
                            return dIssue.id === parseInt(this.$store.state.UI.issueId)
                        })
                    })
                    //console.log("Found Index: " + issueIndex)
                    // Open issue modal with the notification data
                   this.issueIndex = issueIndex
                   this.foundIssue = foundIssue
                   this.foundBurst = foundBurst
                   this.foundCampaign = foundCampaign
                   this.openIssue(this.issueIndex, this.foundIssue, this.foundBurst, this.foundCampaign)
                   return;
            },
            truncate(str, maxlength) {
                return (str.length > maxlength) ?
                str.slice(0, maxlength - 1) + '…' : str;
            },
            addCampaignStatus() {
                this.campaign.map(campaign => {
                // Live Campaigns
                if (moment().isBetween(campaign.startDate, moment(campaign.endDate).add(1, 'days'))) {
                    campaign.status = { type: 'live', color: 'primary' }
                }
                // Pending Campaigns
                else if (moment(new Date()).isBefore(campaign.startDate) || campaign.startDate === null ||  campaign.endDate === null) {
                    campaign.status = { type: 'pending', color: 'tertiary' }
                }
                // Finished Campaigns
                else if (moment(campaign.endDate).isBefore(new Date())) {
                    campaign.status = { type: 'completed', color: 'secondary' }
                } 

                return campaign
                })
            },
            // Find all bursts in a campaign
            findBursts(campaign){
                let bursts = []
                campaign.campaignBursts.forEach((campaignBurst) => {
                    bursts.push(campaignBurst)
                })
                return bursts
            },
            // Find an owner in a burst
            findBurstOwner(burst){
                let owner = null
                if (burst.owner) {
                    owner = burst.owner
                    owner.burstId = burst.id
                    owner.burstName = burst.name
                    owner.deliveryType = burst.deliveryType.name
                    return owner
                }
                return owner
            },
            
            menuClicked(){
                this.$nextTick()
            },
            openStakeholderModal(data, campaign, type){
                if(type === 'manager'){
                    // FInd the burst in this.campaigns where manager.burstId === campaignBurst.id
                    this.selectedBurst = this.campaign.find((campaign) => campaign.id === data.campaignId).campaignBursts.find((campaignBurst) => campaignBurst.id === data.burstId)
                    this.selectedCampaign = campaign
                    this.showStakeholdersDialog = true
                } else {
                    this.selectedBurst = data
                    this.selectedCampaign = campaign
                    this.showStakeholdersDialog = true
                }
            },
            // Function to open the managers card
            openModal() {
                this.menu = !this.menu;
            },
            // Function to open the edit campaign modal
            clickAction(selectedCampaign, action) {
                this.$emit('clickAction', selectedCampaign, action)
            },
            // Function to open/close the panels
            toggleAll() {
                this.panelModel = this.panelModel.length === this.campaign.length ? [] : this.campaign.map((_, index) => index+1)
                this.$nextTick()
            },
            // Progress v-progress-circular
            getProgress(campaign) {
                /* Get the total number of bursts without the bursts where workflowStatus.id === 0 (not started) or workflowStatus.categories is empty
                let totalBursts = 0
                campaign.campaignBursts.forEach((burst) => {
                    if (burst.workflowStatus.id !== 0 && burst.workflowStatus.categories.length > 0) {
                        totalBursts++
                    }
                })
                let completedBursts = 0
                campaign.campaignBursts.forEach((burst) => {
                    if (burst.workflowStatus.status === 1) {
                        completedBursts++
                    }
                })
                */
                let totalTasks = 0
                let completedTasks = 0
                // Get the total number of tasks and the number of completed tasks
                campaign.campaignBursts.forEach(burst => {
                    if(burst){
                        if(burst.workflowStatus && burst.workflowStatus.categories){
                            if(burst.workflowStatus.categories.length > 0){
                                burst.workflowStatus.categories.forEach(category => {
                                    category.dIs.forEach(dIssue => {
                                        dIssue.tasks.forEach(task => {
                                            totalTasks++
                                            if(task.s === 1)
                                                completedTasks++
                                        })
                                    })
                                })
                            }
                        }
                    }
                })
                // Return the percentage of completed tasks and round it to the nearest integer, if there's no tasks, return 0
                if(totalTasks === 0)
                    return 0
                else
                    return Math.round((completedTasks / totalTasks) * 100)
            },
            // Pagination
            pageChange(e) {
                this.pagination.page = e
                this.$nextTick()
            },
            rowsPerPageChange(e) {
                this.pagination.rowsPerPage = e
                this.$nextTick()
            },
            // Filter color based on issue status
            filterColor(status) {
                switch(status) {
                    case 1:
                        return '#499F68'
                    case 2:
                        return 'grey'
                    case 3:
                        return '#F464A9'
                    case 4:
                        return '#4AA6D5'
                    case 0:
                        return 'transparent'
                    default:
                        return 'transparent'
                }  
            },
            // Modals Controllers
            createIssue(burst) {
                this.selectedBurst = burst
                this.createIssueModal = true
            },
            openIssue(index, issue, burst, campaign){
                this.selectedIssue = issue
                this.selectedBurst = burst
                this.selectedCampaign = campaign
                this.issueIndex = index
                this.openIssueModal = true
            },
            closeIssue(){
                this.openIssueModal = false
                this.createIssueModal = false
                this.$store.commit('UI/SET_ISSUE_TASK_MODAL', false);
            },
            openDeleteBurstMilestone(burst){
                this.openDeleteIssueModal = true
                this.selectedBurstToDelete = burst
            },
            // Delete Burst Milestone
            async confirmDeleteBurstMilestone(){
                await IssueTask.removeProfileMilestone(this.selectedBurstToDelete.id).then(() => {
                    this.$root.$emit('updateOrDeleteCampaign', 'delete', this.selectedBurstToDelete)
                    this.openDeleteIssueModal = false
                    this.$root.$emit('snackbarSuccess', 'Burst deleted successfully')
                }).catch((err) => {
                    this.$root.$emit('snackbarError', ''+ err.response.data.message)
                })
            },
        }
    }
</script>
<style scoped>
.v-card {
    margin-top:0;
    margin-bottom:0;
}
.together{
    min-width: 0
  }
.v-expansion-panel-header {
    padding: 0px 24px 0px 0px;
}
.subIssues {
    width: 100%;
}
.subIssue {
    cursor: pointer;
    /* add transition speed 300 */
    transition: 0.3s;
    height: 25px;
}
.subIssue:first-child,
.roundCorners  {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.subIssue:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subIssue:not(:last-child) {
    border-right: 0.1px solid black;
}
.subIssue:hover {
    /* add transition speed 300 */
    transition: 0.3s;
    background-color: black;
}
.customSpacing:not(:first-child) {
    /* If not first item, add margin top 1 em */
    margin-top: 1.5em;
}
</style>