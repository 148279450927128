<template>
  <v-row class="pa-4">
    <v-col cols="12" class="d-flex justify-space-between">
      <!-- Fonts -->
      <div>
        <v-row class="align-center mt-4">
          <v-icon large class="mr-2 mb-1">mdi-format-font</v-icon>
          <h1 style="font-weight: 400;">{{ (campaignAssets && campaignAssets.length > 0) ? 'Assets Added' : 'Add Assets' }}</h1>
        </v-row>
        <v-row class="d-flex justify-start flex-start align-start" style="column-gap: 1.5em;">
          <v-card
            max-width="344"
            min-width="344"
            v-for="(asset, index) in campaignAssets" :key="index"
            outlined
          >
            <v-card-text>
              <div>{{ asset.media.originalFileName }}</div>
              <p class="text-h5 text--primary">
                Used in {{ asset.usageCount }} assets.
              </p>
              <p>Embeddable: {{ asset.embeddable }}</p>
              <div class="text--primary">
                Some text
              </div>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn
                text
                color="primary"
                @click="revealCard(index)"
              >
                Learn More
              </v-btn>
              <!--TODO: On Hover change color + add tooltip + modal for confirmation-->
              <v-btn class="mr-2" >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <v-card
                v-if="isCardRevealed(index)"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-card-text class="pb-0">
                  <p class="text-h4 text--primary">
                    Origin
                  </p>
                  <p>late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’ </p>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    
                    text
                    color="primary"
                    @click="hideCard(index)"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-row>
      </div>
       <!-- drag & drop upload -->
       <div
          v-if="$store.state.Permissions.campaignEdit"
          @click="$refs.fontsUpload.click()"
          @dragover.prevent
          @drop.prevent
          style="width: 150px;"
        >
          <input
            @click="$refs.fontsUpload.value = ''"
            ref="fontsUpload"
            type="file"
            multiple
            hidden
            @change="uploadFont"
          />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <div
                    ref="dragDiv"
                    v-bind="attrs"
                    v-on="on"
                    @drop="dragFile"
                    @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                    @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                    style="
                      background-color: transparent;
                      padding: 0.66em 0em;
                      transition: 0.1s;
                    "
                    :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                    class="rounded"
                  >
                    <div style="width:100%; text-align:center;">
                      <v-icon large>
                        mdi-cloud-upload
                      </v-icon>
                    </div>
                  </div>
                </v-hover>
              </template>
                <span>Upload Fonts</span>
            </v-tooltip>
          </div>
      </v-col>

    <v-dialog
      v-model="showFileUploadModal"
      @click:outside="filesForUpload = null; showFileUploadModal = false;"
      width="500"
    >
      <v-card>
        <v-card-title class="pa-4 white--text primary">
          Upload Assets
        </v-card-title>

        <div class="pa-2">
          <ul>
            <li v-for="(file, index) in filesForUpload" :key="index"><span class="text-h5">{{ file.name }}</span></li>
          </ul>

          <div class="d-flex justify-end mt-4">
            <v-btn 
              @click="uploadAssets()"
              color="primary"
              class="mr-2"
            >Upload</v-btn>
            <v-btn 
              @click="filesForUpload = null; showFileUploadModal = false;"
              color="red" 
            >Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
  // Services
  import CampaignController from '@/services/controllers/Campaign'

  export default {
    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        localCampaign: null,
        campaignAssets: null,
        filesForUpload: null,
        showFileUploadModal: false,
        revealedCards: [],
      }
    },
    
    created () {
      // Make local copy
      this.localCampaign = JSON.parse(JSON.stringify(this.campaign))

      this.getAssets()
    },

    methods: {
      // Cards reveal aniamtion
      revealCard(index) {
        // Set the 'reveal' status for the card at the given index to true.
        this.revealedCards.push({ index, reveal: true });
      },
      hideCard(index) {
        // Find the index of the card with the given index in the 'revealedCards' array and set its 'reveal' status to false.
        const cardIndex = this.revealedCards.findIndex((card) => card.index === index);
        if (cardIndex !== -1) {
          this.revealedCards.splice(cardIndex, 1);
        }
      },
      isCardRevealed(index) {
        // Check if the card at the given index is revealed based on the 'revealedCards' array.
        return this.revealedCards.some((card) => card.index === index && card.reveal);
      },
      // end card reveal animation
      async getAssets() {
        const { data = null } = await CampaignController.getAssets(this.campaign.id)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        this.campaignAssets = data
      },

      async uploadAssets() {
        for (let index = 0; index < this.filesForUpload.length; index++) {
          const file = this.filesForUpload[index];
          
          const formData = new FormData()
          formData.append('file', file)
          formData.append('campaignId', this.campaign.id)

          const mediaRes = await CampaignController.uploadMedia(formData).catch(error => { 
            this.$root.$emit("snackbarError", error.response.data.message) 
          })

          await CampaignController.addAsset({ campaignId: this.campaign.id, mediaId: mediaRes.data.id })
          // Catch error
          .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
        }

        this.filesForUpload = null
        this.showFileUploadModal = false

        this.getAssets()
      },

      // Purpose: Execute after files are selected from the explorer
      uploadFont(e) {
        const files = Object.values(e.target.files)

        this.filesForUpload = files
        this.showFileUploadModal = true
      },

      // Purpose: Execute after files are dragged & dropped from the explorer
      dragFile (e) {
        const files = Object.values(e.dataTransfer.files)

        this.filesForUpload = files
        this.showFileUploadModal = true
      },
    },
  }
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>