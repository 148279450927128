<template>
  <div v-if="hasCampaignProcessPriorityPermission">
      <!-- mdi processing icon -->
      <!-- prevent click propogation -->

      <v-icon
          @click.stop="setProcessPriority(campaign)"
          :style="{ color: campaign.processPriority > 0 ? 'green' : 'grey' }"
          class="pa-2"
      >mdi-counter</v-icon>
  </div>
</template>
  
<script>
import ReportingController from '@/services/controllers/Reporting'

export default {
  props: {
      campaign: {
          type: Object,
          default: {}
      },
      hasCampaignProcessPriorityPermission: {
          type: Boolean,
          default: false
      }
  },

  data() {
    return { 
      
    };
  },
  computed: {
    
  },
  methods: {
      async setProcessPriority (campaign) {
          var processPriority = campaign.processPriority+1;
          if(processPriority > 1)
              processPriority = 0; // Only 2 process priority levels for now, effectively on and off
          
          // this.$root.$emit('setProcessPriority', campaign.id, processPriority) // TODO
          ReportingController.setReportProcessPriority({
                  campaignId: campaign.id, 
                  processPriority: processPriority
              })
              .then((res) => {
                  campaign.processPriority = res.data.processPriority;
                  this.$root.$emit('snackbarSuccess', 'Processing turned ' + (campaign.processPriority > 0 ? "on" : "off") + " for " + campaign.name)
              })
              .catch((err) => {
                  this.$root.$emit('snackbarError', ''+ err.response.data.message)
              })
      }
  }
};
</script>