<template>
  <v-data-table
    v-if="campaigns && campaigns.length > 0"
    :headers="headers"
    :items="campaigns"
    :items-per-page="-1"
    class="elevation-1 mt-1 mx-3"
    :style="{ width: `${100}%` }"
    hide-default-footer
    disable-pagination
  >
    <!-- Name -->
    <template v-slot:item.name="{ item }">
      <div
        @click="clickRow(item.id)"
        class="d-flex justify-between align-center mouse-pointer font-weight-bold ml-n4"
        :style="{ width: `${100}%`, height: `${50}px` }"
      >
        <v-tooltip right :color="getVuetifyColor(item.status.color)">
          <template v-slot:activator="{ attrs, on }">
            <span
              v-bind="attrs"
              v-on="on"
              :style="{ 
                borderLeft: `7px solid ${getVuetifyColor(item.status.color)}`, height: '50px' 
              }"
            />
          </template>
          <span :style="{ color: 'white' }">{{ `Campaign is ${item.status.type}` }}</span>
        </v-tooltip>
        <span>
          <v-img
            v-if="item.logo"
            :src="item.logo.urlThumb"
            width="60"
            height="50"
            style="cursor: pointer"
          />
          <v-img
            v-else
            src="d-clamp-holder.jpg"
            width="60"
            height="50"
            style="cursor: pointer"
          />
        </span>
        <span class="ml-3">{{ item.name }}</span>
      </div>
    </template>

    <!-- Description -->
    <template v-slot:item.description="{ item }">
      <div
        @click="clickRow(item.id)"
        class="d-flex align-center mouse-pointer"
        :style="{ width: `${100}%`, height: `${30}px` }"
      >{{ hideText(item.description, 30, true) }}</div>
    </template>

    <!-- K Number -->
    <template v-slot:item.kNumber="{ item }">
      <div
        @click="clickRow(item.id)"
        class="d-flex align-center mouse-pointer"
        :style="{ width: `${100}%`, height: `${30}px` }"
      >{{ item.kNumber }}</div>
    </template>

    <!-- Start Date -->
    <template v-slot:item.startDate="{ item }">
      <div
        @click="clickRow(item.id)"
        class="d-flex align-center mouse-pointer"
        :style="{ width: `${100}%`, height: `${30}px` }"
      >{{ item.startDate | Moment_DD_MMM_YYYY }}</div>
    </template>

    <!-- End Date -->
    <template v-slot:item.endDate="{ item }">
      <div
        @click="clickRow(item.id)"
        class="d-flex align-center mouse-pointer"
        :style="{ width: `${100}%`, height: `${30}px` }"
      >{{ item.endDate | Moment_DD_MMM_YYYY }}</div>
    </template>

    <!-- Actions -->
    <template
      v-slot:item.actions="{ item }" 
      v-if="$store.state.Permissions.campaignEdit || $store.state.Permissions.usersAndStakeholders"
    >
      <div
        class="d-flex align-center"
        :style="{ width: `${100}%`, height: `${30}px` }"
      > 
        <span
          v-if="$store.state.Permissions.usersAndStakeholders"
          class="px-1"
        >
          <v-icon
            @click="clickAction(item, 'showStakeholdersDialog')"
            color="tertiary"
            class="mouse-pointer"
          >mdi-account-multiple</v-icon>
        </span>
        <span
          v-if="$store.state.Permissions.campaignEdit"
          class="px-1"
        >
          <v-icon
            @click="clickAction(item, 'showCampaignEditFormDialog')"
            class="mouse-pointer"
            color="primary"
          >mdi-pencil</v-icon>
        </span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
  // Mixins
  import helpersConcatText from '@/mixins/helpers/concatText'
  import vuetifyColors from '@/mixins/vuetify/colors'

  // External libraries
  import moment from 'moment'

  export default {
    mixins: [
      helpersConcatText,
      vuetifyColors
    ],

    filters: {
      Moment_DD_MMM_YYYY: function (date) {
        return moment(date).format('DD MMM YYYY')
      },
    },

    props: {
      campaigns: {
        type: Array,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },
    },

    methods: {
      clickRow(id) {
        this.$emit('clickRow', id)
      },

      clickAction(selectedCampaign, action) {
        this.$emit('clickAction', selectedCampaign, action)
      }
    },
  }
</script>