var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.campaigns && _vm.campaigns.length > 0)?_c('v-data-table',{staticClass:"elevation-1 mt-1 mx-3",style:({ width: ((100) + "%") }),attrs:{"headers":_vm.headers,"items":_vm.campaigns,"items-per-page":-1,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-between align-center mouse-pointer font-weight-bold ml-n4",style:({ width: ((100) + "%"), height: ((50) + "px") }),on:{"click":function($event){return _vm.clickRow(item.id)}}},[_c('v-tooltip',{attrs:{"right":"","color":_vm.getVuetifyColor(item.status.color)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('span',_vm._g(_vm._b({style:({ 
              borderLeft: ("7px solid " + (_vm.getVuetifyColor(item.status.color))), height: '50px' 
            })},'span',attrs,false),on))]}}],null,true)},[_c('span',{style:({ color: 'white' })},[_vm._v(_vm._s(("Campaign is " + (item.status.type))))])]),_c('span',[(item.logo)?_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.logo.urlThumb,"width":"60","height":"50"}}):_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":"d-clamp-holder.jpg","width":"60","height":"50"}})],1),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mouse-pointer",style:({ width: ((100) + "%"), height: ((30) + "px") }),on:{"click":function($event){return _vm.clickRow(item.id)}}},[_vm._v(_vm._s(_vm.hideText(item.description, 30, true)))])]}},{key:"item.kNumber",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mouse-pointer",style:({ width: ((100) + "%"), height: ((30) + "px") }),on:{"click":function($event){return _vm.clickRow(item.id)}}},[_vm._v(_vm._s(item.kNumber))])]}},{key:"item.startDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mouse-pointer",style:({ width: ((100) + "%"), height: ((30) + "px") }),on:{"click":function($event){return _vm.clickRow(item.id)}}},[_vm._v(_vm._s(_vm._f("Moment_DD_MMM_YYYY")(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center mouse-pointer",style:({ width: ((100) + "%"), height: ((30) + "px") }),on:{"click":function($event){return _vm.clickRow(item.id)}}},[_vm._v(_vm._s(_vm._f("Moment_DD_MMM_YYYY")(item.endDate)))])]}},(_vm.$store.state.Permissions.campaignEdit || _vm.$store.state.Permissions.usersAndStakeholders)?{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",style:({ width: ((100) + "%"), height: ((30) + "px") })},[(_vm.$store.state.Permissions.usersAndStakeholders)?_c('span',{staticClass:"px-1"},[_c('v-icon',{staticClass:"mouse-pointer",attrs:{"color":"tertiary"},on:{"click":function($event){return _vm.clickAction(item, 'showStakeholdersDialog')}}},[_vm._v("mdi-account-multiple")])],1):_vm._e(),(_vm.$store.state.Permissions.campaignEdit)?_c('span',{staticClass:"px-1"},[_c('v-icon',{staticClass:"mouse-pointer",attrs:{"color":"primary"},on:{"click":function($event){return _vm.clickAction(item, 'showCampaignEditFormDialog')}}},[_vm._v("mdi-pencil")])],1):_vm._e()])]}}:null],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }