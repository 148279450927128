var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ height: "inherit" }),on:{"click":function($event){return _vm.clickCard(_vm.campaign.id)}}},[_c('theme-material-card',{staticClass:"mouse-pointer pa-4",style:({ height: "100%" }),attrs:{"image":"","min-width":"300","max-width":"300","color":"#DDDDDD"},scopedSlots:_vm._u([{key:"image",fn:function(){return [(_vm.campaign.logo)?_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.campaign.logo.urlThumb,"height":"250","width":"300"}}):_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":"d-clamp-holder.jpg","width":"300","height":"250"}})]},proxy:true}])},[_c('v-card-title',{staticClass:"justify-center font-weight-bold pb-1",staticStyle:{"word-break":"break-word","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.campaign.name)+" ")]),_c('v-card-text',{staticClass:"body-1 text-center font-weight-light pb-1",style:({ 
        margin: 0, 
        padding: 0, 
        marginBottom: ((2) + "rem"), 
        color: _vm.invertColor('#333', _vm.$vuetify.theme.dark) 
      })},[_vm._v(" "+_vm._s((_vm.hideText(_vm.campaign.description, 100, true)))+" ")]),(_vm.$store.state.Permissions.campaignKNumberView)?_c('v-card-text',{staticClass:"d-flex justify-center",style:({ 
        position: 'absolute',
        bottom: ((8) + "px"), 
        left: 0, 
        right: 0, 
        color: _vm.invertColor('#333', _vm.$vuetify.theme.dark), 
        fontSize: ((14) + "px") 
      })},[_vm._v(" "+_vm._s((_vm.campaign.kNumber && _vm.campaign.kNumber.length > 0) ? '#' + _vm.campaign.kNumber : null)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"d-flex justify-center",style:({ 
        position: 'absolute', 
        bottom: ((-10) + "px"), 
        left: '-15px', 
        right: 0,
        fontSize: ((14) + "px"),
        color: _vm.invertColor('#333', _vm.$vuetify.theme.dark),
      })},[_vm._v(" "+_vm._s(_vm._f("Moment_DD_MMM_YYYY")(_vm.campaign.startDate))+" - "+_vm._s(_vm._f("Moment_DD_MMM_YYYY")(_vm.campaign.endDate))+" ")]),(_vm.$store.state.Permissions.campaignEdit || _vm.$store.state.Permissions.usersAndStakeholders)?_c('div',{staticClass:"d-flex",style:({ 
        position: 'absolute', 
        left: '0px', 
        bottom: 0, 
        height: '26px', 
        width: '30px', 
        zIndex: '10' 
      })}):_vm._e(),(_vm.$store.state.Permissions.campaignEdit || _vm.$store.state.Permissions.usersAndStakeholders)?_c('div',{staticClass:"d-flex",style:({ 
        position: 'absolute', 
        right: '45px', 
        bottom: '3px', 
        height: '26px', 
        width: '30px' 
      })},[(_vm.$store.state.Permissions.usersAndStakeholders)?_c('div',{staticClass:"px-1",on:{"click":function($event){$event.stopPropagation();return _vm.clickAction(_vm.campaign, 'showStakeholdersDialog')}}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-account-multiple")])],1):_vm._e(),(_vm.$store.state.Permissions.campaignEdit)?_c('div',{staticClass:"px-1",on:{"click":function($event){$event.stopPropagation();return _vm.clickAction(_vm.campaign, 'showCampaignEditFormDialog')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }