<template>
    <v-dialog
        v-model="createIssueModalUpd"
        max-width="750px"
    >
        <v-card>
            <v-card-title class="white--text primary">Create Issue in {{ selectedBurst.name }} of {{selectedCampaign.name}}</v-card-title>
            <v-card-text>
                <!-- row with an + icon btn on the right 
                <div class="d-flex justify-end mb-4">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                color="primary"
                                @click="createIssueModalUpd = false"
                            >
                                <v-icon x-small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add a new issue</span>
                    </v-tooltip>
                    
                </div>-->
                <v-form>
                    <v-row>
                        <!-- Category-->
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-select
                                v-model="category"
                                :items="selectedBurst.workflowStatus.categories"
                                item-text="n"
                                label="Category"
                                outlined
                                return-object
                                required
                            />
                        </v-col>
                        <!-- Title -->
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-combobox
                                :disabled="category === null"
                                v-model="title"
                                :items="filteredIssueItems"
                                item-text="title"
                                label="Title"
                                outlined
                                required
                                return-object
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- Description -->
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-combobox
                                :disabled="category === null"
                                v-model="description"
                                :items="filteredIssueItems"
                                item-text="d"
                                height="50"
                                label="Description"
                                outlined
                                required
                                return-object
                            />
                        </v-col>
                        <!-- Role -->
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-combobox
                                :disabled="roles.length === 0"
                                v-model="role"
                                :items="sortedRoles"
                                item-text="name"
                                label="Role"
                                outlined
                                required
                                return-object
                            />
                        </v-col>
                    </v-row>
                    
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-6 mt-n4">
                <v-col
                    cols="12"
                    class="text-center ma-0 pa-0"
                >
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="createIssue()"
                        :disabled="category === null || title === null || role === null || loading"
                        :loading="loading"
                    >
                        Confirm
                    </v-btn>
                    <v-btn
                        color="red"
                        :disabled="loading"
                        :loading="loading"
                        @click="createIssueModalUpd = false">
                        Cancel
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// Controllers 
import IssueTask from '@/services/controllers/IssueTask'
import Stakeholder from '@/services/controllers/Stakeholder'
export default {
    data: () => ({
        title: null,
        description: null,
        category: null,
        roles: [],
        role: null,
        loading: false,
    }),
    inject: ['closeIssue'],
    props: {
        createIssueModal: {
            type: Boolean,
            default: false,
            required: true,
        },
        selectedBurst: {
            type: Object,
            default: () => {},
            required: true,
        },
        selectedCampaign: {
            type: Object,
            default: () => {},
            required: true,
        },
        categories: {
            type: Array,
            default: () => [],
            required: true,
        },
        allDIssues: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    watch: {
        // If titles changes, get the id and fill the description
        title: {
            handler: function (val) {
                if (val !== null) {
                    var findDIssue = this.allDIssues.find(issue => issue.title === val.title);
                    //console.log(findDIssue)
                    if(findDIssue){
                        this.description = findDIssue.d
                    } else {
                        this.description = ''
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        createIssueModalUpd: {
            get() {
                return this.createIssueModal
            },
            set() {
                this.closeIssue()
            },
        },
        filteredIssueItems() {
            // Check if category is null
            if (this.category === null) {
                return []
            }
            return this.allDIssues.filter(issue => issue.categoryId === this.category.id)
        },
        sortedRoles(){
            if(this.roles.length === 0) return []
            // every role has a propery .hieryarchy with is an integer in range of 0 to 900, sort by ascending, remove super admin and admin 
            //return this.roles.sort((a, b) => a.hierarchy - b.hierarchy).filter(role => role.id !== 1 && role.id !== 2)
            return this.roles.sort((a, b) => a.hierarchy - b.hierarchy)
        }
    },
    async mounted() {
        // Get all availabel roles
        this.loading = true
        await this.getAllRoles()
    },
    methods: {
        async getAllRoles(){
            await Stakeholder.getPrimaryRoles()
            .then((res) => {
                this.roles = res.data
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.loading = false
            })
        },
        async createIssue() {
            this.loading = true
            // Check if the title is an object, then call assIssueTo burst with null for title and descri[ption. but the dIsueId is this.title.id
            if (typeof this.title === 'object') {
                await IssueTask.addIssueToBurst({
                    dIssueId: this.title.id,
                    campaignBurstId: this.selectedBurst.id,
                    title: null,
                    description: null,
                    startDateOffset: 0,
                    categoryId: this.category.id,
                    roleId: this.role.id,
                })
                .then((res) => {
                    this.$root.$emit('snackbarSuccess', 'Task added to ' + this.selectedBurst.name + ' successfully')
                    this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                    this.closeIssue()
                })
                .catch((err) => {
                    this.$root.$emit('snackbarError', err.response.data.message)
                    console.log(err)
                })
                return
            } else {
                await IssueTask.addIssueToBurst({
                dIssueId: null,
                campaignBurstId: this.selectedBurst.id,
                title: this.title,
                description: this.description,
                startDateOffset: 0,
                categoryId: this.category.id,
                roleId: this.role.id,
                })
                .then((res) => {
                    this.$root.$emit('snackbarSuccess', 'Task added to ' + this.selectedBurst.name + ' successfully')
                    this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                    this.closeIssue()
                })
                .catch((err) => {
                    this.$root.$emit('snackbarError', err.response.data.message)
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
            }
        },
    },
}
</script>